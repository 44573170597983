.loader {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 11111111;
    .spiner {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 200px;
        height: 113px;
        position: absolute;
        z-index: 111;
        top: calc(50% - 55.65px);
        left: calc(50% - 100px);
    }
}